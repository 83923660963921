.mainContainerHome {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.homeFlexContainer {
  display: flex;
}


.infoContainerHome {
  width: 60%;
  justify-content: flex-start;
  text-align: justify;
  color: white;
  font-size: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.streamerContainer {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: 2rem;
  background-color: var(--primaryColor);
  border-radius: 20px;
  padding: 1rem;
}

.searchStreamerContainer {
  
  margin-top: 6rem;
  width: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

#areYouAStreamerTitle {
  font-size: 0.8em;
  color: var(--titleColor);
  font-weight: bold;
  text-align: center;
}

#searchStreamer div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#searchStreamer div > input + label {
  color: var(--creamColor);
}

#createAccountHomeButton {
  height: 35px;
  white-space: nowrap;
  color: var(--secondaryColor);
  background-color: white;
  border-color: transparent;
  transition: transform 0.4s;
}

#createAccountHomeButton:hover {
  background-color: var(--secondaryColor);
  color: white;
  background-image: none;
  border-color: transparent;
  transform: scale(1.05);
}


#searchStreamer div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}


#searchStreamerButton {
  height: 35px;
  margin-top: 1rem;
  color: var(--secondaryColor);
  background-color: white;
  border-color: transparent;
  transition: transform 0.4s;
}

#searchStreamerButton:hover {
  background-color: var(--primaryColor);
  color: white;
  background-image: none;
  border-color: transparent;
  cursor: pointer;
  transform: scale(1.05);
}

#snackbar {
  background-color: rgb(202, 0, 0);
}

.contentWindow {
  padding: 10px 0px;
}