.mainContainerLoginAccount {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.usernameContainerLogin {
  margin-bottom: 1rem;
  display: flex;
}

.passwordContainerLogin {
  margin-bottom: 1rem;
  display: flex;
}

.loginAccountButtonContainer {
  display: flex;
  justify-content: center;
}

#usernameInputLogin div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#usernameInputLogin div > input + label {
  color: var(--creamColor);
}

#usernameInputLogin div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}

#passwordInputLogin div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#passwordInputLogin div > button > div {
  border: none;
  outline: none;
  background: none;
}

#passwordInputLogin div > input + label {
  color: var(--creamColor);
}

#passwordInputLogin div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}


#loginAccountButton {
  height: 35px;
  margin-top: 0.5rem;
  color: var(--secondaryColor);
  background-color: white;
  border-color: transparent;
  transition: transform 0.4s;
}

#loginAccountButton:hover {
  background-color: var(--primaryColor);
  color: white;
  background-image: none;
  border-color: transparent;
  transform: scale(1.05);
}

#errorText {
  white-space: initial;
}


.contentWindow {
  padding: 10px 0px;
}
