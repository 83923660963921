.mainContainerDonate {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.donateToContainer {
  display: flex;
  width: 30%;
  text-align: center;
  justify-content: center;
}

.donateToContainer > h1{
  color: var(--creamColor);
}

.nameContainer {
  width: 30%;
  margin-bottom: 1rem;
  display: flex;
}

.messageContainer {
  width: 30%;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.amountContainer {
  width: 30%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-flow: row;
}

.amountInput {
  flex: 5;
  margin-right: auto;
}
.tokenDropdown {
  flex: 1;
  margin-left: auto;
}


.amountButtonsContainer {
  width: 30%;

  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.amountInCryptoLabel {
  color: white;
  margin-top: 5px;
  font-size: 1.5vh;
  float: right;
}

.donateButtonContainer {
  display: flex;
  justify-content: center;
}


#amountButton1 {
  background-color: white;
  border-color: var(--primaryColor);
  color: var(--secondaryColor);
  margin-right: 0.5rem;
  transition: transform 0.4s;
}

#amountButton1:hover {
  background-image: none;
  transform: scale(1.05);
}
#amountButton2 {
  background-color: white;
  border-color: var(--primaryColor);
  color: var(--secondaryColor);
  margin-right: 0.5rem;
  transition: transform 0.4s;
}

#amountButton2:hover {
  background-image: none;
  transform: scale(1.05);
}

#amountButton3 {
  background-color: white;
  border-color: var(--primaryColor);
  color: var(--secondaryColor);
  margin-right: 0.5rem;
  transition: transform 0.4s;
}
#amountButton3:hover {
  background-image: none;
  transform: scale(1.05);
}

#amountButton4 {
  background-color: white;
  border-color: var(--primaryColor);
  color: var(--secondaryColor);
  margin-right: 0.5rem;
  transition: transform 0.4s;
}
#amountButton4:hover {
  background-image: none;
  transform: scale(1.05);
}

#amountButton5 {
  background-color: white;
  border-color: var(--primaryColor);
  color: var(--secondaryColor);
  margin-right: 0.5rem;
  transition: transform 0.4s;
}

#amountButton5:hover {
  background-image: none;
  transform: scale(1.05);
}

#amountButton6 {
  background-color: white;
  border-color: var(--primaryColor);
  color: var(--secondaryColor);
  transition: transform 0.4s;
}

#amountButton6:hover {
  background-image: none;
  transform: scale(1.05);
}

#nameInput div {
  border: 1px solid var(--creamColor);
  outline: none;}

#nameInput div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}

#nameInput div > input + label {
  color: var(--creamColor);
}

#amountInput div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#amountInput div:hover {
  border: 1px solid var(--primaryColor);
  outline: none;
}

#amountInput div > input + label {
  color: var(--creamColor);
}

#dropdown div {
  border-color: var(--creamColor);
}


#dropdown div:hover {
  border-color: var(--primaryColor);
}

#message div {
  background-color: #282c34;
  border-color: var(--creamColor);
}

#message div:hover {
    border: 1px solid var(--primaryColor);
    outline: none;
  
}

#message label{
  color: var(--creamColor);
  background-color: #282c34;
}

#message div:hover > label {
  color: whitesmoke;
}

#donateButton {
  height: 35px;
  margin-top: 0.5rem;
  color: var(--secondaryColor);
  background-color: white;
  border-color: transparent;
  transition: transform 0.4s;
}

#donateButton:hover {
  background-color: var(--primaryColor);
  color: white;
  background-image: none;
  border-color: transparent;
  transform: scale(1.05);
}

#modalText {
  white-space: initial;
}

.contentWindow {
  padding: 10px 0px;
}

