.mainContainerAccountCreated {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleContainerAccountCreated >h1 {
  margin-left: 1rem;
  margin-right: 1rem;
  color: var(--creamColor);
  margin-bottom: 0px;
}

.infoContainerAccountCreated {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  color: white;
  font-size: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.infoContainer > button {
  background-color: white;
}

.infoContainer > button:hover {
  background-color: var(--primaryColor);
  background-image: none;
}

#copyButton {
  
  margin-left: 1rem;
}

#goBackToHomeButton {
  margin-top: 2rem;
  color: var(--secondaryColor);
  height: 40px;
  transition: transform 0.4s;
}

#goBackToHomeButton:hover {
  background-color: var(--primaryColor);
  color: white;
  background-image: none;
  transform: scale(1.05);
}

#snackbar {
  background-color: var(--secondaryColor);
}

.contentWindow {
  padding: 10px 0px;
}