:root {
  --primaryColor: #f9c01c !important;
  --secondaryColor: #1574a3 !important;
  --creamColor: #fff8e9b9;
  --backgroundColor: #282c34;
  --textColor: #313131;
}

.headerLogo {
  padding-left: 20px;
  filter: opacity(0.5) drop-shadow(20 80 60 blue);
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.ant-layout-header {
  background-color: var(--primaryColor);
  display: flex;
  height: 12vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.ant-layout-content {
  background-color: var(--backgroundColor);
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}
.mainContainerApp {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}
.chainDropdown div{
  border-color: transparent;
}

#chainDropdown  {
  background-color: white;
  border-radius: 20px;
}

#header {
  display: flex;
  flex-direction: row;
  width: 100%;
}
#connectButtonContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 0;
}

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #282c347d;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


a:visited {
  text-decoration: none;
  color: var(--secondaryColor);
}

a:link {
  color: var(--primaryColor);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--secondaryColor);
}

a:focus {
  text-decoration: none;
  color: var(--secondaryColor);
}

a:active {
  text-decoration: none;
  color: var(--secondaryColor);
}

.contentWindow {
  padding: 50px 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: visible; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: var(--secondaryColor); /* Black background color */
  color: white; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 20px; /* Rounded borders */
  padding: 16px; /* Padding */
  font-size: 3vh;
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 60px; /* 30px from the bottom */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 60px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 60px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 60px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 60px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
