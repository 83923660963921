.titleContainerCreateAccount>h1 {
  margin-left: 1rem;
  margin-right: 1rem;
  color: var(--creamColor);
  margin-bottom: 0px;
}

.infoContainerCreateAccount {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  text-align: justify;
  color: white;
  font-size: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
}

.mainContainerCreateAccount {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}
.usernameContainer {
  margin-bottom: 1rem;
  display: flex;
}

.passwordContainer {
  margin-bottom: 1rem;
  display: flex;
}

.walletAddressContainer {
  display: flex;
  margin-bottom: 1rem;
}

.imageResourceContainer {
  display: flex;
  margin-bottom: 1rem;
}

.audioResourceContainer {
  display: flex;
  margin-bottom: 1rem;
}

.colorDropdownContainer {
  display: flex;
  margin-bottom: 1rem;
}

.createAccountButtonContainer {
  display: flex;
  justify-content: center;
}

#usernameInput div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#usernameInput div > input + label {
  color: var(--creamColor);
}

#usernameInput div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}

#passwordInput div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#passwordInput div > button > div {
  border: none;
  outline: none;
  background: none;
}

#passwordInput div > input + label {
  color: var(--creamColor);
}

#passwordInput div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}

#walletInput div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#walletInput div > input + label {
  color: var(--creamColor);
}

#walletInput div:hover{
  border: 1px solid var(--primaryColor);
  outline: none;
}

#imageInput div {
  border: 1px solid var(--creamColor);
  outline: none;
}

#createAccountButton {
  height: 35px;
  margin-top: 0.5rem;
  color: var(--secondaryColor);
  background-color: white;
  border-color: transparent;
  transition: transform 0.4s;
}

#createAccountButton:hover {
  background-color: var(--primaryColor);
  color: white;
  background-image: none;
  border-color: transparent;
  transform: scale(1.05);
}

#errorText {
  white-space: initial;
}

.contentWindow {
  padding: 10px 0px;
}
